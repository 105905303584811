.counterCard{
    background-color: #fff;
    box-sizing: border-box;
    border-radius: 20px;
    overflow: hidden;
    width: min(300px, 100%);
    margin-inline: 10px;
    flex-shrink: 1;
    margin-block: 10px;
}

.counterCard h3{
    font-size: 50px;
    text-align: center;
}

.counterCard p{
    font-size: 20px;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
}

.counterCard div{
    padding: 10px;
}

.counterCard h2{
    background-color: #03559d;
    margin-block: 0;
    text-align: center;
    padding-block: 20px;
    color: white;
}

.grid{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
