.section{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.section button{
    max-width: 200px;
}

.section h3{
    font-size: 40px;
    color: red;
}
