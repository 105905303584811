.mainHeader{
    background-color: #003669;
    padding-block: 20px;
}
.mainTitle{
    color: white;
    margin-block: 0;
    text-align: center;
}

.container{
    width: 90%;
    min-width: 300px;
    max-width: 1200px;
    margin-inline: auto;
    display: flex;
    justify-content: center;
}

.image{
    width: 30px;
    margin-right: 10px;
}

.activo{
    background-color: #3588de;
}

.buttons{
    text-decoration: none;
    color: white;
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;

}

.switcher{
    display: grid;
    grid-template-columns: 1fr 1fr;
    background-color: #015096;
}
